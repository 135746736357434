const palette = {
    primary: {
        // light: will be calculated from palette.primary.main,
        main: '#3d86bd',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
    },

    secondary: {
        // light: will be calculated from palette.secondary.main,
        main: '#231a60',
        // dark: will be calculated from palette.secondary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
    },

    neutral: {
        50: '#F5F7F9',
        100: '#F2F3F7',
        200: '#E8ECF1',
        main: '#bcbec0',
        300: '#8c8e90',
        400: '#5f6163',
        800: '#353739',
        900: '#0f1113',
    },
};

export default palette;
