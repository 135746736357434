import React from 'react';

import {
    Paper,
    Box,
    makeStyles,
    Typography,
    TextField,
    Divider,
    Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        minHeight: '50vh',
        maxWidth: '60%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
    },

    panel: {
        padding: theme.spacing(2),
        minWidth: 300,
    },
    title: {
        fontSize: '35px',
        color: theme.palette.neutral['900'],
        paddingLeft: '25px',
    },
    space: {
        marginRight: '25px',
    },
    btn: {
        marginLeft: '25px',
    },
}));
export default function Company() {
    const classes = useStyles();

    const handleSubmite = (e) => {
        e.preventDefault();
        const company = e.target.company.value;

        console.log('Company name : ' + company);
    };
    return (
        <div>
            <Box mb={4}>
                <Typography className={classes.title}>New Company</Typography>
                <Button
                    className={classes.btn}
                    color="primary"
                    variant="contained"
                >
                    Back
                </Button>
            </Box>
            <Divider />
            <Box className={classes.wrapper}>
                <Paper className={classes.panel} elevation={0}>
                    <form onSubmit={handleSubmite}>
                        <Box>
                            <TextField
                                name="company"
                                id="company-name"
                                label="Company Name"
                                fullWidth
                            />
                        </Box>
                        <Box>
                            <TextField
                                id="address-1"
                                label="Address 1"
                                fullWidth
                            />
                        </Box>
                        <Box>
                            <TextField
                                id="address-2"
                                label="Address 2"
                                fullWidth
                            />
                        </Box>
                        <Box>
                            <TextField
                                className={classes.space}
                                id="city"
                                label="City"
                            />
                            <TextField
                                className={classes.space}
                                id="state"
                                label="State"
                            />
                            <TextField
                                className={classes.space}
                                id="zip"
                                label="Zip"
                            />
                        </Box>
                        <Box>
                            <TextField id="country" label="Country" fullWidth />
                        </Box>
                        <Box>
                            <TextField id="pib/vat" label="PIB/VAT" fullWidth />
                        </Box>

                        <Box>
                            <TextField id="SWIFT" label="SWIFT" fullWidth />
                        </Box>

                        <Box>
                            <TextField id="iban" label="IBAN" fullWidth />
                        </Box>

                        <Box>
                            <TextField id="phone" label="Phone" fullWidth />
                        </Box>

                        <Box>
                            <TextField
                                id="email"
                                label="email"
                                fullWidth
                                type="email"
                            />
                        </Box>

                        <Box mb={4}>
                            <TextField id="website" label="website" fullWidth />
                        </Box>
                        <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            type="submit"
                        >
                            Register
                        </Button>
                    </form>
                </Paper>
            </Box>
        </div>
    );
}
