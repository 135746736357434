import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import {
    getFirestore,
    doc,
    getDoc,
    query,
    where,
    getDocs,
    collection,
    addDoc,
    orderBy,
} from 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

//
// AUTH handlers
//

export const auth = getAuth();
export const signIn = signInWithEmailAndPassword;

//
// FIRESTORE handlers
//

export const db = getFirestore();

export const fetchInvoice = async (invoiceId) => {
    if (invoiceId) {
        const docRef = doc(db, 'invoices', invoiceId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log('Invoice data fetched:', docSnap.data());
            return docSnap.data();
        } else {
            // doc.data() will be undefined in this case
            throw 'No such document!';
        }
    } else {
        console.error('Invoice ID not provided. Returning empty obj: {}');
        return {};
    }
};

export const fetchInvoices = async () => {
    const companies = await fetchCompanies();

    if (companies && companies[0]) {
        const companyId = companies[0].id;

        const q = query(
            collection(db, 'invoices'),
            where('companyId', '==', companyId),
            orderBy('datedTimestamp', 'desc')
        );
        const responseArray = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            responseArray.push({ ...doc.data(), id: doc.id });
        });
        console.log('invoices array fetched:', responseArray);

        return responseArray;
    } else {
        return [];
    }
};

export const createInvoice = async (data) => {
    const docRef = await addDoc(collection(db, 'invoices'), data);
    return docRef;
};

export const updateInvoice = async (id, data) => {};

export const fetchCompany = async (companyId) => {
    const docRef = doc(db, 'companies', companyId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log('Company data fetched:', docSnap.data());
        return docSnap.data();
    } else {
        // doc.data() will be undefined in this case
        throw 'No such document!';
    }
};

export const fetchCompanies = async () => {
    const q = query(
        collection(db, 'companies'),
        where('ownerUUID', '==', auth.currentUser.uid)
    );
    const responseArray = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        responseArray.push({ ...doc.data(), id: doc.id });
    });
    console.log('Companies array fetched:', responseArray);

    return responseArray;
};

export const fetchClient = async (clientId) => {
    const docRef = doc(db, 'clients', clientId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log('Client data fetched:', docSnap.data());
        return docSnap.data();
    } else {
        // doc.data() will be undefined in this case
        throw 'No such document!';
    }
};

export const fetchClients = async () => {
    const q = query(
        collection(db, 'clients'),
        where('ownerUUID', '==', auth.currentUser.uid)
    );
    const responseArray = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        responseArray.push({ ...doc.data(), id: doc.id });
    });
    console.log('Clients array fetched:', responseArray);

    return responseArray;
};

//
// DEFAULT
//

export default app;
