import { Box, Typography } from '@material-ui/core';
import React from 'react';

export default function PageHeader({ title, actions, ...otherProps }) {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
        >
            <Typography variant="h2">{title}</Typography>
            {actions}
        </Box>
    );
}
