import React from 'react';
import Section from '../../layout/Section';
import { Box } from '@material-ui/core';

export default function InvoicesBox() {
    return (
        <Section title="Invoices: ">
            <Box>Invoice table - last 3 incoices goes here</Box>
        </Section>
    );
}
