import { Box, makeStyles } from '@material-ui/core';
import Sidebar from './Sidebar';
import Panel from './Panel';

const useStyles = makeStyles((theme) => ({
    app: {
        flex: 1,
        display: 'flex',
        alignItems: 'stretch',
        paddingTop: 76,
        width: '100%',
        // maxWidth: 1440,
        margin: '0 auto',
    },
}));

export default function AppBody({ children }) {
    const classes = useStyles();

    return (
        <Box className={classes.app}>
            <Sidebar />
            <Panel>{children}</Panel>
        </Box>
    );
}
