import { Box, Button } from '@material-ui/core';
import DataTable from '../../common/DataTable';
import { NavLink } from 'react-router-dom';
import useGetters from '../../../hooks/useGetters';
import PageHeader from '../../common/PageHeader';
import { Add } from '@material-ui/icons';
import { useState, useEffect } from 'react';
import NumberFormatCustom from '../../common/NumberFormatCustom';
import { getCurrencySymbol } from '../../../lib/helpers';
import MoreMenu from '../../common/MoreMenu';
import { useHistory } from 'react-router';

export default function InvoicesIndex() {
    const { loading, error, data } = useGetters('invoices');
    const [rows, setRows] = useState([]);
    const history = useHistory();

    const columns = [
        {
            field: 'internalId',
            headerName: 'Invoice #',
            width: 150,
        },
        {
            field: 'client',
            headerName: 'Client',
            width: 150,
        },
        { field: 'dated', headerName: 'Dated', width: 90 },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            width: 150,
        },
        {
            field: 'total',
            headerName: 'Total',
            type: 'number',
            width: 110,
            align: 'right',
        },
        {
            field: 'actions',
            headerName: '',
            width: 50,
            align: 'right',
        },
    ];

    useEffect(() => {
        if (data && data.length > 0) {
            let rows = data.map((item) => ({
                ...item,
                client: item?.client?.name ?? '',
                total: transformTotal(
                    item?.total ?? 0,
                    item?.currency ?? 'usd'
                ),
                actions: (
                    <MoreMenu
                        menuItems={[
                            {
                                text: 'Edit',
                                onClick: () => {
                                    history.push(`/invoice/create/${item.id}`);
                                },
                            },
                            {
                                text: 'View/Print',
                                onClick: () => {
                                    history.push(`/invoice/${item.id}`);
                                },
                            },
                            {
                                text: 'Delete',
                                disabled: true,
                                onClick: () => {},
                            },
                            {
                                text: 'Draft',
                                disabled: true,
                                onClick: () => {},
                            },
                        ]}
                    />
                ),
            }));
            setRows(rows);
        }
    }, [data]);

    const transformTotal = (total, currency) => (
        <NumberFormatCustom
            prefix={getCurrencySymbol(currency)}
            value={total}
            displayType={'text'}
            onChange={(v) => v}
        />
    );

    return (
        <div>
            <PageHeader
                title="Invoices"
                actions={
                    <Box>
                        <NavLink to="/invoice/create">
                            <Button variant="contained" color="primary">
                                <Add /> New Invoice
                            </Button>
                        </NavLink>
                    </Box>
                }
            />
            {loading ? (
                // if loading - show some loader, do not try to access data
                <div>Loading...</div>
            ) : (
                // Loading finished, but there can be an error - check if error, if no, show invoice finally
                <div>
                    {error ? (
                        <div style={{ color: 'red' }}>{error}</div>
                    ) : (
                        // This is our final template - if everything is OK
                        // you can even extract this into separate component and pass whole invoice variable - ex:
                        // <InvoiceTemplate invoice={invoice} />
                        // This keeps your files organized
                        // This is a wrapper that handles fetching, loading, errors
                        // and inner one (<InvoiceTemplate />) only displays when everything is OK.
                        <div>
                            {/* <h3>Invoices: </h3>
                            <ul>
                                {data &&
                                    data.map((invoice) => (
                                        <li key={invoice.id}>
                                            {invoice.dated} -{' '}
                                            {invoice.internalId}
                                        </li>
                                    ))}
                            </ul>
                            <h2>...</h2> */}

                            <DataTable
                                rows={rows}
                                columns={columns}
                                // pageSize={5}
                                // checkboxSelection
                                // disableSelectionOnClick
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
