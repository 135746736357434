import useGetters from '../../hooks/useGetters';

export default function CompanyTest() {
    const { loading, error, data } = useGetters('clients');

    return (
        <div>
            {loading ? (
                // if loading - show some loader, do not try to access data
                <div>Loading...</div>
            ) : (
                // Loading finished, but there can be an error - check if error, if no, show invoice finally
                <div>
                    {error ? (
                        <div style={{ color: 'red' }}>{error}</div>
                    ) : (
                        // This is our final template - if everything is OK
                        // you can even extract this into separate component and pass whole invoice variable - ex:
                        // <InvoiceTemplate invoice={invoice} />
                        // This keeps your files organized
                        // This is a wrapper that handles fetching, loading, errors
                        // and inner one (<InvoiceTemplate />) only displays when everything is OK.
                        <div>
                            <ul>
                                {data &&
                                    data.map((client) => (
                                        <li key={client.id}>{client.name}</li>
                                    ))}
                            </ul>
                            <h2>...</h2>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
