import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { ReactComponent as Logo } from '../../assets/econce_logo.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.neutral[50],
        padding: 16,
        justifyContent: 'center',
        flexDirection: 'row',
        maxWidth: '100vw',
        overflow: 'hidden',
        borderBottom: `1px solid ${theme.palette.neutral[100]}`,
        boxShadow: 'none',

        '@media print': {
            borderBottom: 'none',
            background: 'transparent',
            marginTop: '8mm',
        },
    },
    logo: {
        position: 'relative',

        '&::before': {
            content: '""',
            display: 'block',
            background: theme.palette.secondary.main,
            width: '100vw',
            height: '100%',
            position: 'absolute',
            left: 'calc(-100vw - 32px)',
            top: -1,
        },

        '&::after': {
            content: '""',
            display: 'block',
            background: theme.palette.primary.main,
            width: '100vw',
            height: '100%',
            position: 'absolute',
            left: 'calc(100% + 32px)',
            top: -1,
        },
    },
}));

export default function DenseAppBar() {
    const classes = useStyles();

    return (
        <AppBar position="fixed" className={classes.root}>
            <Box className={classes.logo}>
                <Logo height={40} />
            </Box>
        </AppBar>
    );
}
