import { Paper, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    panelWrapper: {
        flex: 1,
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },

    panelPaper: {
        padding: theme.spacing(2),
        flex: 1,
        maxWidth: 1200,
        overflow: 'hidden',
    },
}));

export default function Panel({ children }) {
    const classes = useStyles();

    return (
        <Box className={classes.panelWrapper}>
            <Paper className={classes.panelPaper} elevation={4}>
                {children}
            </Paper>
        </Box>
    );
}
