import React from 'react';
import { IconButton, MenuItem, Menu } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

export default function MoreMenu({ menuItems }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems &&
                    menuItems.map((item, index) => (
                        <MenuItem
                            key={index}
                            onClick={item.onClick}
                            disabled={item.disabled || false}
                        >
                            {item.text}
                        </MenuItem>
                    ))}
                {!menuItems && <MenuItem disabled>Nothing here</MenuItem>}
            </Menu>
        </>
    );
}
