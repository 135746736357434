import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    StyledBox: {
        background: theme.palette.neutral[50],
    },
}));

export default function Section({ children, title }) {
    const classes = useStyles();

    return (
        <Box
            className={classes.StyledBox}
            p={2}
            pt={1}
            display="flex"
            flexDirection="column"
            flex="1"
        >
            <Box mb={1}>
                <Typography variant="h5">{title}</Typography>
            </Box>
            <Box>{children}</Box>
        </Box>
    );
}
