// TODO: Allow multiple companies

// for now, just take the first company from the array - we assume user will have only one company.
// use useEffect to set the selected company upon successful get/fetch

import React, { useEffect } from 'react';
import useGetters from '../../../hooks/useGetters';
import { Box, Typography, makeStyles } from '@material-ui/core';
import CenteredLoader from '../../common/CenteredLoader';

const useStyles = makeStyles({
    companyPicker: {
        minHeight: 300,
        padding: '8px 40px 16px 0px',
    },
});

export default function CompanyPicker({ selected, setSelected }) {
    const classes = useStyles();

    const { data: companies, loading, error } = useGetters('companies');

    useEffect(() => {
        if (companies && companies[0]) {
            setSelected(companies[0]);
        }
    }, [companies]);

    return (
        <Box className={classes.companyPicker}>
            {loading && <CenteredLoader />}
            {error && <Typography color="error">{error}</Typography>}

            {!loading && !error && (
                <>
                    {selected && (
                        <Box>
                            <Box mb={2}>
                                <Typography variant="h4">
                                    {selected.name}
                                </Typography>
                            </Box>
                            <Typography>{selected.address1}</Typography>
                            <Typography>{selected.address2}</Typography>
                            <Typography>
                                {selected.zip} {selected.city}
                            </Typography>
                            <Typography>{selected.country}</Typography>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
}
