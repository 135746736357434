import useGetters from '../../../hooks/useGetters';
import PageHeader from '../../common/PageHeader';
import { Box, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';

export default function ClientsIndex() {
    const { loading, error, data } = useGetters('clients');

    return (
        <div>
            <PageHeader
                title="Clients"
                actions={
                    <Box>
                        <NavLink to="/clients/create">
                            <Button variant="contained" color="primary">
                                <Add /> New Client
                            </Button>
                        </NavLink>
                    </Box>
                }
            />
            {loading ? (
                // if loading - show some loader, do not try to access data
                <div>Loading...</div>
            ) : (
                // Loading finished, but there can be an error - check if error, if no, show invoice finally
                <div>
                    {error ? (
                        <div style={{ color: 'red' }}>{error}</div>
                    ) : (
                        // This is our final template - if everything is OK
                        // you can even extract this into separate component and pass whole invoice variable - ex:
                        // <InvoiceTemplate invoice={invoice} />
                        // This keeps your files organized
                        // This is a wrapper that handles fetching, loading, errors
                        // and inner one (<InvoiceTemplate />) only displays when everything is OK.
                        <div>
                            <ul>
                                {data &&
                                    data.map((client) => (
                                        <li key={client.id}>{client.name}</li>
                                    ))}
                            </ul>
                            <h2>...</h2>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
