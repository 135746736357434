import React from 'react';
import NumberFormat from 'react-number-format';

export default function NumberFormatCustom({
    inputRef,
    onChange,
    name,
    ...other
}) {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            // isNumericString
        />
    );
}
