const initialState = {
    initialLoading: true,

    user: {},

    companies: {
        array: [],
        loaded: false,
    },

    company: {},

    clients: {
        array: [],
        loaded: false,
    },

    client: {},

    invoices: {
        array: [],
        loaded: false,
    },

    invoice: {
        single: true,
        data: null,
        loaded: false,
    },
};

const devState = {
    // DEVELOPMENT STATE SET
    // Use this if you need to overwrite some state variables for testing
};

export default process.env.NODE_ENV === 'development'
    ? { ...initialState, ...devState }
    : initialState;
