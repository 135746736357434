import actions from './actions.js';

const reducer = (state, action) => {
    switch (action.type) {
        /**
         * Set user data
         */
        case actions.SET_INITIAL_LOADING_DONE:
            console.log('[SET_INITIAL_LOADING_DONE]');
            return { ...state, initialLoading: false };

        case actions.SET_CLIENTS:
            return {
                ...state,
                clients: { loaded: true, array: action.payload },
            };

        case actions.SET_COMPANIES:
            return {
                ...state,
                companies: { loaded: true, array: action.payload },
            };

        case actions.SET_INVOICES:
            return {
                ...state,
                invoices: { loaded: true, array: action.payload },
            };

        case actions.SET_INVOICE:
            return {
                ...state,
                invoice: { single: true, loaded: true, data: action.payload },
            };

        case actions.INVALIDATE:
            const invalidatedState = { ...state };
            invalidatedState[action.payload] = {
                ...state[invalidatedState],
                loaded: false,
            };
            return invalidatedState;

        default:
            return state;
    }
};

export default reducer;
