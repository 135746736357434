import { createTheme } from '@material-ui/core/styles';
import palette from './palette';

const defaultTheme = createTheme();

const theme = createTheme({
    palette: palette,

    typography: {
        fontFamily: [
            'Lato',
            '-apple-system',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        h6: {
            fontSize: 12,
            textTransform: 'uppercase',
        },
    },

    overrides: {
        // EX: Style sheet name ⚛️
        MuiButton: {
            // EX: Name of the rule
            text: {
                // EX: Some CSS
                color: 'white',
            },
        },

        MuiDivider: {
            root: {
                marginTop: defaultTheme.spacing(2),
                marginBottom: defaultTheme.spacing(2),
            },
        },

        MuiOutlinedInput: {
            input: {
                padding: `${defaultTheme.spacing(2)}px ${defaultTheme.spacing(
                    2
                )}px`,
            },
        },
    },
});

export default theme;
