import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    loaderWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default function CenteredLoader() {
    const classes = useStyles();

    return (
        <Box className={classes.loaderWrapper}>
            <CircularProgress />
        </Box>
    );
}
