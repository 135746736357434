import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import InvoicesBox from './home/InvoicesBox';
import Section from '../layout/Section';

const useStyles = makeStyles((theme) => ({
    Grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2),
    },

    Box: { display: 'flex' },

    Clients: {},
}));

export default function Index() {
    const classes = useStyles();

    return (
        <Box className={classes.Grid}>
            <Box className={classes.Box}>
                <InvoicesBox />
            </Box>
            <Box className={classes.Box}>
                <Section>Asd</Section>
            </Box>
        </Box>
    );
}
