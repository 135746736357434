import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';

import {
    Paper,
    Box,
    makeStyles,
    Typography,
    TextField,
    Divider,
    Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.neutral['50'],
        padding: theme.spacing(2),
        paddingTop: 92,
        boxSizing: 'border-box',
    },

    panel: {
        padding: theme.spacing(2),
        minWidth: 300,
    },
}));

export default function Login() {
    const classes = useStyles();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { login, currentUser } = useAuth();

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('[handleSubmit]: ', email, password);

        if (!email || !password) return;

        login(email, password)
            .then((userCredential) => {
                console.log('[handleLogin]: success');
                const user = userCredential.user;
                history.push('/');
            })
            .catch((error) => {
                // const errorCode = error.code;
                // const errorMessage = error.message;
                setError(error.message);
            });
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    return (
        <Box className={classes.wrapper}>
            <Paper className={classes.panel}>
                <Box mb={2}>
                    <Typography variant="h5" color="initial">
                        Login
                    </Typography>
                </Box>
                <Divider />
                <Box mt={4} display="flex" flexDirection="column">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <Box mb={3}>
                            <TextField
                                id="email"
                                label="Email"
                                fullWidth
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </Box>
                        <Box mb={4}>
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                fullWidth
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </Box>
                        <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            type="submit"
                        >
                            Login
                        </Button>
                    </form>
                </Box>
            </Paper>
        </Box>
    );
}
