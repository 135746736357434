import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCompany } from '../../api/firebase';

export default function CompanyTest() {
    const { id } = useParams();
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true); // set loading to true initialy - set to false after fetching is done
    const [error, setError] = useState(false);

    useEffect(() => {
        const getCompanyData = async () => {
            try {
                const data = await fetchCompany(id);
                console.log('[CompanyTest]: data fetched success: ', data);
                setError(false); // reset error to false, if there was an error previously
                setCompany(data); // set invoice data to read in template
                setLoading(false); // when data is loaded, set loading to false to display data
            } catch (error) {
                console.error('[CompanyTest]: ', error);
                setError('Error or missing permissions.');
                setLoading(false); // even if error occured, set loading to false, bacuse API loading actually finished
            }
        };

        if (id) {
            getCompanyData();
        }
    }, [id]); // IMPORTANT: [id] here ensures that the function will be run whenever id in url is changed.

    return (
        <div>
            {loading ? (
                // if loading - show some loader, do not try to access data
                <div>Loading...</div>
            ) : (
                // Loading finished, but there can be an error - check if error, if no, show invoice finally
                <div>
                    {error ? (
                        <div style={{ color: 'red' }}>{error}</div>
                    ) : (
                        // This is our final template - if everything is OK
                        // you can even extract this into separate component and pass whole invoice variable - ex:
                        // <InvoiceTemplate invoice={invoice} />
                        // This keeps your files organized
                        // This is a wrapper that handles fetching, loading, errors
                        // and inner one (<InvoiceTemplate />) only displays when everything is OK.
                        <div>
                            <h1>Name: {company.name}</h1>
                            <h2>...</h2>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
