import {
    Box,
    Checkbox,
    makeStyles,
    FormControl,
    InputLabel,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@material-ui/icons';

const useStyles = makeStyles({
    invoiceMeta: {
        paddingBottom: 4,
    },

    inputWrap: {
        width: 239,
        textAlign: 'right',
    },

    inputGroup: {
        display: 'flex',
        width: 520,
        justifyContent: 'space-between',
    },

    datePicker: {
        width: 180,
    },

    check: {
        margin: '0 16px',
        marginTop: 22,
    },
});

const dueLength = 21;

export default function InvoiceMeta({ data, setData }) {
    const classes = useStyles();

    // calc due automatically
    // TODO: User can set default dueDateLength in settings - can pull from there.
    const [linkedDueDate, setLinkedDueDate] = useState(true);

    useEffect(() => {
        if (linkedDueDate && data.dated) {
            updateField('dueDate', data.dated.plus({ days: dueLength }));
        }
    }, [data.dated]);

    const toggleLinkedDueDate = () => {
        // if wasn't linked, and link is triggered, automatically update dueDate to correct value
        if (!linkedDueDate) {
            updateField('dueDate', data.dated.plus({ days: dueLength }));
        }
        setLinkedDueDate(!linkedDueDate);
    };

    const updateField = (field, value) => {
        let newData = { ...data };
        newData[field] = value;
        setData(newData);
    };

    return (
        <Box className={classes.invoiceMeta}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MMM dd, yyyy"
                        margin="normal"
                        label="Invoice date"
                        value={data.dated || DateTime.now()}
                        onChange={(date) => updateField('dated', date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                        className={classes.datePicker}
                    />

                    <Checkbox
                        checked={linkedDueDate}
                        onChange={toggleLinkedDueDate}
                        icon={<LinkOffIcon />}
                        checkedIcon={<LinkIcon />}
                        name="linkedDueDate"
                        title={
                            linkedDueDate
                                ? 'Unlink Due date from Invoice date'
                                : 'Link Due date to Invoice date'
                        }
                        className={classes.check}
                    />

                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MMM dd, yyyy"
                        margin="normal"
                        label="Due date"
                        value={
                            data.dueDate ||
                            DateTime.now().plus({ days: dueLength })
                        }
                        onChange={(date) => updateField('dueDate', date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        disabled={linkedDueDate}
                        autoOk={true}
                        className={classes.datePicker}
                    />
                </Box>
            </MuiPickersUtilsProvider>
        </Box>
    );
}
