const actions = {
    SET_INITIAL_LOADING_DONE: 'SET_INITIAL_LOADING_DONE',
    SET_CLIENTS: 'SET_CLIENTS',
    SET_COMPANIES: 'SET_COMPANIES',
    SET_INVOICES: 'SET_INVOICES',
    SET_INVOICE: 'SET_INVOICE',
    INVALIDATE: 'INVALIDATE',
};

export default actions;
