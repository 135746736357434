import { useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    makeStyles,
    IconButton,
    InputAdornment,
    OutlinedInput,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { Add, RemoveCircleOutline } from '@material-ui/icons';
import classNames from 'classnames';
import { getCurrencySymbol } from '../../../lib/helpers';
import NumberFormatCustom from '../../common/NumberFormatCustom';

const useStyles = makeStyles({
    invoiceItems: {},
    line: {},

    textField: {
        marginRight: 8,
    },

    unit: {
        width: 120,
    },

    description: {
        flexGrow: 1,
    },

    number: {
        width: 120,

        '& input': {
            textAlign: 'right',
        },
        '& input::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },

    total: {
        width: 120,
    },
});

export default function InvoiceItems({ items, setItems, currency }) {
    const classes = useStyles();

    // sets an empty item on load to show first fields row
    useEffect(() => {
        if (items && items.length === 0) {
            addItem();
        }
    }, []);

    const updateField = (index, field, value) => {
        let newItems = [...items];
        newItems[index][field] = value;

        if (field === 'qty' || field === 'price') {
            newItems[index].total = newItems[index].qty * newItems[index].price;
        }

        setItems(newItems);
    };

    const addItem = () => {
        let newItems = [...items];
        newItems.push({
            description: '',
            unit: '',
            qty: 0,
            price: 0,
            total: 0,
        });
        setItems(newItems);
    };

    const removeItem = (index) => {
        let newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };

    const currencySymbol = getCurrencySymbol(currency);

    return (
        <Box className={classes.invoiceItems}>
            {items.map((line, index) => (
                <Box display="flex" mb={1} className={classes.line} key={index}>
                    <TextField
                        className={classNames(
                            classes.textField,
                            classes.description
                        )}
                        id=""
                        label="Description"
                        value={items[index].description}
                        onChange={(e) =>
                            updateField(index, 'description', e.target.value)
                        }
                        variant="outlined"
                    />
                    <TextField
                        className={classNames(classes.textField, classes.unit)}
                        id=""
                        label="Unit"
                        value={items[index].unit}
                        onChange={(e) =>
                            updateField(index, 'unit', e.target.value)
                        }
                        variant="outlined"
                    />
                    <TextField
                        className={classNames(
                            classes.textField,
                            classes.number
                        )}
                        id=""
                        label="Qty"
                        value={items[index].qty || 0}
                        onChange={(e) =>
                            updateField(index, 'qty', e.target.value)
                        }
                        variant="outlined"
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    />

                    <FormControl
                        fullWidth
                        className={classNames(
                            classes.textField,
                            classes.number
                        )}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="outlined-adornment-amount">
                            Price
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            value={items[index].price || 0}
                            onChange={(e) =>
                                updateField(index, 'price', e.target.value)
                            }
                            startAdornment={
                                <InputAdornment position="start">
                                    {currencySymbol}
                                </InputAdornment>
                            }
                            labelWidth={40}
                            inputComponent={NumberFormatCustom}
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        className={classNames(
                            classes.textField,
                            classes.number
                        )}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="outlined-adornment-amount">
                            Total
                        </InputLabel>
                        <OutlinedInput
                            disabled
                            id="outlined-adornment-amount"
                            value={items[index].total}
                            onChange={(e) =>
                                updateField(index, 'total', e.target.value)
                            }
                            startAdornment={
                                <InputAdornment position="start">
                                    {currencySymbol}
                                </InputAdornment>
                            }
                            labelWidth={40}
                            inputComponent={NumberFormatCustom}
                        />
                    </FormControl>
                    <IconButton onClick={() => removeItem(index)}>
                        <RemoveCircleOutline />
                    </IconButton>
                </Box>
            ))}
            <Box mt={4}>
                <Button variant="contained" onClick={addItem}>
                    <Add />
                    <span>Add Item</span>
                </Button>
            </Box>
        </Box>
    );
}
