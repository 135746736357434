import React from 'react';
import AppBody from './AppBody';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    appWrapper: {
        background: theme.palette.neutral['200'],
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
}));

export default function Main({ children }) {
    const classes = useStyles();

    return (
        <Box className={classes.appWrapper}>
            <AppBody>{children}</AppBody>
        </Box>
    );
}
