import { Box, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { People, Description, AccountCircle, Home } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    sidebar: {
        width: 200,
        margin: theme.spacing(2),
    },

    inner: {
        padding: theme.spacing(2),
        minHeight: '100%',
        display: 'flex',

        '& ul': {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            width: '100%',
        },
    },

    item: {
        display: 'block',
        borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
    },

    link: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: '100%',
        display: 'flex',
        alignItems: 'center',

        textTransform: 'uppercase',
    },

    accountItem: {
        marginTop: 'auto',
        borderBottom: 'none',
    },
}));

export default function Sidebar() {
    const classes = useStyles();

    return (
        <Box className={classes.sidebar}>
            <nav className={classes.inner}>
                <ul>
                    <li className={classNames(classes.item)}>
                        <Link className={classes.link} to="/">
                            <Home />
                            &nbsp;<span>Home</span>
                        </Link>
                    </li>
                    <li className={classNames(classes.item)}>
                        <Link className={classes.link} to="/invoices">
                            <Description />
                            &nbsp;<span>Invoices</span>
                        </Link>
                    </li>
                    <li className={classNames(classes.item)}>
                        <Link className={classes.link} to="/clients">
                            <People />
                            &nbsp;<span>Clients</span>
                        </Link>
                    </li>
                    <li
                        className={classNames(
                            classes.item,
                            classes.accountItem
                        )}
                    >
                        <Link
                            className={classNames(classes.link)}
                            to="/account"
                        >
                            <AccountCircle />
                            &nbsp;<span>Account</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </Box>
    );
}
