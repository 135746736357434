import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme/theme';
import { Box, makeStyles } from '@material-ui/core';
import Main from './components/layout/Main';
import Login from './components/pages/Login';
import Header from './components/layout/Header';
import { StateProvider } from './store/store';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Index from './components/pages/Index';
import Company from './components/pages/Company';
import InvoicePrint from './components/pages/invoice/InvoicePrint';
import CompanyTest from './components/helperComponents/CompanyTest';
import ClientTest from './components/helperComponents/ClientTest';
import ClientsTest from './components/helperComponents/ClientsTest';
import CreateInvoice from './components/pages/invoice/CreateInvoice';
import InvoicesIndex from './components/pages/invoices/InvoicesIndex';
import ClientsIndex from './components/pages/clients/ClientsIndex';

const useStyles = makeStyles((theme) => ({
    '@global': {
        '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
        },

        body: {
            fontFamily: 'Lato, sans-serif',

            '& ul': {
                listStyleType: 'none',
            },

            '& a': {
                textDecoration: 'none',
                color: 'inherit',
            },
        },
    },
}));

function App() {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <StateProvider>
                    <Header />
                    <Router>
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <ProtectedRoute exact path="/">
                                {/* Main - is a layout component to be used. Later we can create different layouts for different page designs */}
                                <Main>
                                    <Index />
                                </Main>
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/company">
                                <Main>
                                    <Company />
                                </Main>
                            </ProtectedRoute>

                            {/*
                             *
                             *  Invoice routes
                             *
                             */}
                            <ProtectedRoute exact path="/invoices">
                                <Main>
                                    <InvoicesIndex />
                                </Main>
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/invoice/create/:id?">
                                <Main>
                                    <CreateInvoice />
                                </Main>
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/invoice/:id">
                                <Main>
                                    <InvoicePrint />
                                </Main>
                            </ProtectedRoute>

                            <ProtectedRoute exact path="/clients">
                                <Main>
                                    <ClientsIndex />
                                </Main>
                            </ProtectedRoute>

                            {/*
                             *
                             *  TEST PAGES - RAW PAGES FOR TESTING FETCH ETC...
                             *
                             */}

                            <ProtectedRoute exact path="/company-test/:id">
                                <Main>
                                    <CompanyTest />
                                </Main>
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/client-test/:id">
                                <Main>
                                    <ClientTest />
                                </Main>
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/clients-test">
                                <Main>
                                    <ClientsTest />
                                </Main>
                            </ProtectedRoute>
                        </Switch>
                    </Router>
                </StateProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
