import {
    makeStyles,
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    Divider,
} from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { getCurrencySymbol } from '../../../lib/helpers';
import useGetters from '../../../hooks/useGetters';
import NumberFormatCustom from '../../common/NumberFormatCustom';

const useStyles = makeStyles((theme) => ({
    invoiceSidebar: {
        background: theme.palette.neutral[50],
        minHeight: '100%',
        minWidth: '100%',
        boxSizing: 'border-box',
    },

    totalLine: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

export default function InvoiceSidebar({
    invoiceMeta,
    setInvoiceMeta,
    total,
    onSubmit,
}) {
    const classes = useStyles();
    const { data: invoices, loading, error } = useGetters('invoices');

    useEffect(() => {
        if (invoices && invoices[0]) {
            // only get invoices from current year
            // const _invoices = [...invoices];
            const currentYear = new Date().getFullYear();

            const currentYearInvoices = invoices.filter((invoice) => {
                const invoiceYear = invoice.internalId.split('/')[1];
                console.log('YEAR', invoiceYear, currentYear);

                if (invoiceYear === currentYear.toString()) {
                    return true;
                } else {
                    return false;
                }
            });

            let max = 0;
            currentYearInvoices.forEach((invoice) => {
                const id = invoice.internalId.split('/')[0];
                const num = parseInt(id);

                if (num > max) {
                    max = num;
                }
            });

            console.log('MAX: ', max);

            const newIndex = max + 1;
            const newId = newIndex + '/' + currentYear;
            updateField('internalId', newId);
        }
    }, [invoices]);

    const currencySymbol = getCurrencySymbol(invoiceMeta.currency);

    const updateField = (field, value) => {
        let newData = { ...invoiceMeta };
        newData[field] = value;
        setInvoiceMeta(newData);
    };

    return (
        <Box className={classes.invoiceSidebar} p={2} pt={4}>
            <Box mb={2} className={classes.inputWrap}>
                <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel id="demo-simple-select-outlined-label">
                        Currency
                    </InputLabel>
                    <Select
                        fullWidth
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={invoiceMeta.currency || 'USD'}
                        onChange={(e) =>
                            updateField('currency', e.target.value)
                        }
                        label="Currency"
                    >
                        <MenuItem value={'USD'}>USD</MenuItem>
                        <MenuItem value={'EUR'}>EUR</MenuItem>
                        <MenuItem value={'RSD'}>RSD</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className={classes.inputWrap} mb={2}>
                <TextField
                    fullWidth
                    label="Invoice Number"
                    variant="outlined"
                    value={invoiceMeta.internalId || ''}
                    onChange={(e) => updateField('internalId', e.target.value)}
                />
            </Box>

            <Box className={classes.inputWrap}>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="filled-helperText"
                    label="Trading place"
                    value={(invoiceMeta && invoiceMeta.tradingPlace) || ''}
                    onChange={(e) =>
                        updateField('tradingPlace', e.target.value)
                    }
                />
            </Box>
            <Divider />
            <Box mb={2}>
                <Typography className={classes.totalLine} variant="h4">
                    <span>Total: </span>
                    <span>
                        {currencySymbol}
                        <NumberFormatCustom
                            value={total}
                            displayType={'text'}
                            onChange={(v) => v}
                        />
                    </span>
                </Typography>
            </Box>
            <Box>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    <SendIcon fontSize="small" />
                    &nbsp;Submit
                </Button>
            </Box>
        </Box>
    );
}
