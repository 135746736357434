export const getCurrencySymbol = (currency) => {
    switch (currency) {
        case 'USD':
            return '$';
            break;
        case 'EUR':
            return '€';
            break;
        default:
            return '';
            break;
    }
};
