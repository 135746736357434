import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

export default function ProtectedRoute({
    component: Component,
    children,
    ...restOfProps
}) {
    const { currentUser, isAuthenticated } = useAuth();

    console.log('[Protected]', currentUser, isAuthenticated);

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                isAuthenticated ? (
                    // <Component {...props} />
                    <>{children}</>
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
}
