import {
    Box,
    Button,
    Dialog,
    List,
    ListItem,
    makeStyles,
    Typography,
    IconButton,
} from '@material-ui/core';
import React, { useState } from 'react';
import useGetters from '../../../hooks/useGetters';
import CenteredLoader from '../../common/CenteredLoader';
import { Edit } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    clientPicker: {
        position: 'relative',
        height: '300px',
        overflow: 'auto',
        background: theme.palette.neutral[50],
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
    },

    editButton: {
        position: 'absolute',
        top: 12,
        right: 12,
    },
}));

export default function ClientPicker({ selected, setSelected }) {
    const classes = useStyles();
    const { data: clients, loading, error } = useGetters('clients');
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <Box className={classes.clientPicker}>
            {loading && <CenteredLoader />}
            {error && <Typography color="error">{error}</Typography>}

            {!error && !loading && (
                <>
                    {!selected ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                        >
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setDialogOpen(true);
                                }}
                            >
                                Select Client
                            </Button>
                        </Box>
                    ) : (
                        <Box width="100%" p={2} pt={1} pr={5}>
                            <IconButton
                                aria-label="delete"
                                className={classes.editButton}
                                size="small"
                                onClick={() => {
                                    setDialogOpen(true);
                                }}
                            >
                                <Edit fontSize="inherit" />
                            </IconButton>
                            <Box mb={2}>
                                <Typography variant="h4" color="initial">
                                    {selected.name}
                                </Typography>
                            </Box>
                            <Typography>{selected.address1}</Typography>
                            <Typography>{selected.address2}</Typography>
                            <Typography>{selected.city}</Typography>
                            <Typography>
                                {selected.state} {selected.zip}
                            </Typography>
                            <Typography>{selected.country}</Typography>
                        </Box>
                    )}

                    <Dialog
                        open={dialogOpen}
                        onClose={() => {
                            setDialogOpen(false);
                        }}
                    >
                        <List>
                            {clients.map((client) => (
                                <ListItem
                                    button
                                    onClick={() => {
                                        setSelected(client);
                                        setDialogOpen(false);
                                    }}
                                    key={client.id}
                                >
                                    {client.name}
                                </ListItem>
                            ))}
                        </List>
                    </Dialog>
                </>
            )}
        </Box>
    );
}
